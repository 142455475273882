<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="pink" dark v-bind="attrs" v-on="on">
          Add Investment Plan
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Add Investment Plan
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="investmentName"
                label="Investment Name"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="dailyReturnRate"
                label="Daily ReturnRate"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="totalReturnRate"
                label="Total ReturnRate"
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                type="number"
                v-model="lowestInvestmentAmount"
                label="Lowest Investment Amount"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="hightestInvestmentAmount"
                label="Hightest Investment Amount"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="duration"
                label="Duration In Months"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="CreateInvestmentPlan()"> Process </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      investmentName: "",
      dailyReturnRate: "",
      totalReturnRate: "",
      lowestInvestmentAmount: "",
      hightestInvestmentAmount: "",
      duration: "",
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    CreateInvestmentPlan() {
      let payload = {
        token: this.getToken,
        investmentName: this.investmentName,
        dailyReturnRate: this.dailyReturnRate,
        totalReturnRate: this.totalReturnRate,
        lowestInvestmentAmount: this.lowestInvestmentAmount,
        hightestInvestmentAmount: this.hightestInvestmentAmount,
        duration: this.duration,
      };
      this.$store.dispatch("CreateInvestmentPlan", payload);
      this.dialog = false;
    },
  },
};
</script>