<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ma-5" color="info" dark v-bind="attrs" v-on="on">
          Edit Plan
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Edit Investment Plan
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="item.investmentName"
                label="Investment Name"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="item.dailyReturnRate"
                label="DailyReturn Rate"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="item.totalReturnRate"
                label="TotalReturn Rate"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="item.lowestInvestmentAmount"
                label="LowestInvestment Amount"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="item.hightestInvestmentAmount"
                label="HightestInvestment Amount"
                filled
              ></v-text-field>
              <v-text-field
                type="number"
                v-model="item.duration"
                label="Duration In Months"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="EditInvestmentPlan()"> Process </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
      email: "",
      password: "",
      userName: "",
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    EditInvestmentPlan() {
      let payload = {
        token: this.getToken,
        investmentId: this.item._id,
        ...this.item,
      };
      this.$store.dispatch("EditInvestmentPlan", payload);
      this.dialog = false;
    },
  },
};
</script>