<template>
  <div>
    <AddInvestmentPlan />
    <v-data-table
      :headers="dessertHeaders"
      :items="getInvestmentPlan"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="_id"
      show-expand
      class="elevation-1"
      :items-per-page="20"
      hide-default-footer
      :loading="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.lowestInvestmentAmount="{ item }">
        <span>{{ DisplayCurrency(item.lowestInvestmentAmount) }}</span>
      </template>
      <template v-slot:item.hightestInvestmentAmount="{ item }">
        <span>{{ DisplayCurrency(item.hightestInvestmentAmount) }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <EditInvestmentPlan :item="item" />
        </td>
      </template>
    </v-data-table>
    <div class="text-center mt-7">
      <v-pagination v-model="page" :length="totalItems" circle></v-pagination>
    </div>
  </div>
</template>

<script>
import currencyFormatter from "currency-formatter";
import AddInvestmentPlan from "../components/AddInvestmentPlan.vue";
import EditInvestmentPlan from "../components/EditInvestmentPlan.vue";

export default {
  components: { AddInvestmentPlan, EditInvestmentPlan },
  data() {
    return {
      page: 1,
      currentPage: 1,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "Investment Name",
          align: "start",
          sortable: false,
          value: "investmentName",
        },
        {
          text: "DailyReturnRate %",
          value: "dailyReturnRate",
          sortable: false,
          align: "center",
        },
        {
          text: "Duration in Month(s) %",
          value: "duration",
          sortable: false,
          align: "center",
        },
        {
          text: "TotalReturnRate %",
          value: "totalReturnRate",
          sortable: false,
          align: "center",
        },
        {
          text: "LowestInvestmentAmount",
          value: "lowestInvestmentAmount",
          sortable: false,
          align: "center",
        },
        {
          text: "HightestInvestmentAmount",
          value: "hightestInvestmentAmount",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    getInvestmentPlan() {
      return this.$store.getters.getInvestmentPlan;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "USD",
      });
    },
    GetInvestmentPlan() {
      let payload = {
        token: this.getToken,
        page: this.page,
      };
      this.$store.dispatch("GetInvestmentPlan", payload);
    },
  },
  created() {
    this.GetInvestmentPlan();
  },
  watch: {
    page: function (val) {
      if (this.page != this.currentPage) {
        this.currentPage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("GetInvestmentPlan", payload);
        return;
      }
    },
  },
};
</script>